<template>
 <div class="w">
    <div class="crumbs">
      <router-link to="/">首页</router-link>
      <span>/</span>
      <router-link to="/noticeList">通知公告</router-link>
      <span>/</span>
      正文
    </div>
    <div class="content">
      <Detail :info="info"></Detail>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import Detail from '../components/Detail'
import { noticeDetail } from '../api'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'NoticeDetail',
  components: {
    Detail
  },
  setup () {
    const info = ref({})
    const route = useRoute()

    onMounted(() => {
      noticeDetail({ id: route.query.id }).then(res => {
        info.value = {
          ...res.result,
          name: res.result.subject
        }
      })
    })

    return { info }
  }
})
</script>

<style lang="scss" scoped>
.content{
  margin-bottom: 70px;
}
</style>
